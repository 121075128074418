<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/favicons/favicon-32x32.png')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              Flower
            </h2>
          </router-link>
        </v-card-title>

        <!-- login form -->
        <v-card-text>
          <v-form
            v-model="valid"
            @submit.prevent="checkForm"
          >
            <v-text-field
              v-model="email"
              :rules="emailRules"
              outlined
              label="Email"
              placeholder="john@example.com"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              :rules="passwordRules"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Mot de passe"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-btn
              block
              color="primary"
              class="mt-6"
              type="submit"
              :disabled="!valid"
            >
              Se connecter
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >
  </div>
</template>

<script>
import { mdiFacebook, mdiTwitter, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'

export default {
  data: () => ({
    isPasswordVisible: false,
    valid: false,
    email: null,
    emailRules: [
      v => !!v || 'L\'e-mail est obligatoire',
      v => /.+@.+\..+/.test(v) || 'L\'e-mail doit avoir un format valide'
    ],
    password: null,
    passwordRules: [
      v => !!v || 'Le mot de passe est obligatoire'
    ],
    socialLink: [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2'
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2'
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437'
      }
    ],
    icons: {
      mdiEyeOutline,
      mdiEyeOffOutline
    }
  }),
  methods: {
    checkForm () {
      this.errors = []

      if (this.valid) {
        this.login()
      }
    },
    login () {
      this.valid = false

      const data = {
        username: this.email,
        password: this.password
      }

      this.$store.dispatch('login', data).then(() => {
        this.valid = true
      })
    }
  }
}
</script>

<style lang="scss">
  @import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
